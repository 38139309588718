export const magnifiRedirectUriBuilder = ({
  redirectUri,
  isRetail,
  accessToken,
  email,
}) =>
  redirectUri +
  (isRetail
    ? `/login?token=${accessToken}&email=${encodeURIComponent(email)}`
    : `/login?response=${encodeURIComponent(
        JSON.stringify({
          token: accessToken,
          email,
        })
      )}`);
