/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import Header from "../layout/header";
import "./index.css";
import Product from "../product";
import { isDev } from "../../utils/helpers";
import { magnifiRedirectUriBuilder } from "./utils";

const products = [
  {
    key: 1,
    name: "Magnifi - Retail",
    group: "Magnifi Retail",
    logo: "https://advisor.magnifi.com/wp-content/uploads/2021/11/Magnifi_byTIFIN-darkgrey.svg",
    tagline:
      "A platform to help advisors understand their prospect’s fears, needs, and wants through risk, planning and personality assessments.",
    redirectUri: isDev
      ? "https://staging.retail.magnifi.com"
      : "https://magnifi.com",
    height: "38px",
    className: "img-name img-name130",
    buttonClass: "common-button-black",
  },
  {
    key: 2,
    name: "Magnifi - Advisor",
    group: "Magnifi Advisor",
    logo: "https://advisor.magnifi.com/wp-content/uploads/2021/11/Magnifi_byTIFIN-darkgrey.svg",
    tagline:
      "Conversational, AI-based micro-planning that answers your prospect’s most pressing financial questions.",
    redirectUri: isDev
      ? "https://test2.magnifi.com"
      : "https://app.magnifi.com",
    height: "38px",
    className: "",
    buttonClass: "common-button-blue",
  },
  {
    key: 3,
    name: "Magnifi - International",
    group: "Magnifi International",
    logo: "https://advisor.magnifi.com/wp-content/uploads/2021/11/Magnifi_byTIFIN-darkgrey.svg",
    tagline:
      "Conversational, AI-based micro-planning that answers your prospect’s most pressing financial questions.(International)",
    redirectUri: isDev
      ? "https://staging.intl.magnifi.com"
      : "https://global.magnifi.com",
    height: "38px",
    className: "",
    buttonClass: "common-button-blue",
  },
];

const Dashboard = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      const accessToken = authState?.accessToken?.accessToken;
      const email = authState?.idToken?.claims?.email;
      const user = await oktaAuth.getUser();
      const userGroups = products
        .filter((product) => user.groups.includes(product.group))
        .map((product) => product.group);
      setGroups(userGroups);
      if (userGroups.length === 1) {
        const product = products.find((product) =>
          userGroups.includes(product.group)
        );
        const isRetail = product.group.toLowerCase().includes("retail");
        window.location.href = magnifiRedirectUriBuilder({
          redirectUri: product.redirectUri,
          isRetail,
          accessToken,
          email,
        });
        return;
      }
      setLoading(false);
    } catch (error) {
      await oktaAuth.signOut();
      setLoading(false);
    }
  };

  const handleRedirect = (groups, redirectUri) => {
    let redirectTo = redirectUri;

    const accessToken = authState?.accessToken?.accessToken;
    const email = authState?.idToken?.claims?.email;
    if (
      groups.includes("Magnifi International") ||
      groups.includes("Magnifi Advisor")
    ) {
      redirectTo = magnifiRedirectUriBuilder({
        redirectUri: redirectUri,
        isRetail: false,
        accessToken,
        email,
      });
    }

    window.location.href = redirectTo;
  };

  if (loading) {
    return <div className="d-flex justify-content-center">Loading....</div>;
  }

  return (
    <div>
      <Header />
      <h2 className="container topTextContent heading">
        {groups.length === 0
          ? "You Are Not A Part Of Any Group"
          : "Please Choose A Product To Continue"}
      </h2>
      <div className="container cardContiner">
        <div className="d-flex justify-content-evenly elementor-container">
          <div className="row">
            {products
              .filter((product) => groups.includes(product.group))
              .map((product) => (
                <Product onContinue={handleRedirect} {...product} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
