import React from "react";

const Product = ({
  name,
  group,
  logo,
  tagline,
  onContinue,
  redirectUri,
  height,
  className,
  buttonClass,
}) => {
  return (
    <div className="col card-deck">
      <div className="card h-100">
        <div className="card-body d-flex justify-content-center text-center">
          <div className="">
            <img
              src={logo}
              height={height}
              className={`img-name attachment-medium_large size-medium_large ${className}`}
              alt={name}
              loading="lazy"
            />
            <p className="card-text">
              <div className="container content">{tagline}</div>
            </p>
          </div>
          <div className="text-center">
            <button
              className={buttonClass}
              onClick={() => onContinue(group,redirectUri)}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
