import queryString from "query-string";
const { query } = queryString.parseUrl(
  "http://localhost:3000/?client_id=0oa1p1hnxocJCY6Lw697&code_challenge=VzA8nC5hZp5wmXvqS69bBdbCzzZHyN23jaEPGdlzPWs&code_challenge_method=S256&nonce=plI6wfNjYfg91YY7yyz0WbBaYnA441uZZCti8wGEKSWGvBJynxQILni0qzaZhBhX&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Flogin%2Fcallback&response_type=code&state=lklBv8qXdr3fjUod87DCahHGqkB6e5qRsK6MPYtIQqsMskOWGxZpGQ3tR1yz4Uy4&scope=openid%20email%20groups"
);
const config = {
  OKTA_AUTH: {
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: window.location.origin + "/login/callback",
    scopes: ["openid", "email", "groups"],
    state: query["state"],
    otp: query["otp"],
    pkce: true,
  },
};
export default config;
